import React, { useEffect, useState } from 'react'
import ImageLayers from './Components/ImageLayers'
import ViewQuestion from './Components/ViewQuestion'
import Background from './Components/Background'
import Header from './Components/Header'
import Footer from './Components/Footer'

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  for(let i in array){
    array[i].index = i
  }

  return array;
}

const _questions = [
  {
    index: 0,
    q: "কলা বউ আসলে কে বা কি",
    a: ['গণেশের স্ত্রী', 'নয় রকমের উদ্ভিদ', 'কার্তিকের স্ত্রী', 'মহিষাসুরের বাহন'],
    c: 1
  },
  {
    index: 1,
    q: 'মা দুর্গার হাতে শঙ্খ অস্ত্রটি কে দান করেন?',
    a: ['শ্রীবিষ্ণু', 'মহাদেব', 'বরুন দেব', 'দেবরাজ ইন্দ্র'],
    c: 2
  },
  {
    index: 2,
    q: 'দেবীর দুর্গার আশীর্বাদে অন্ধকার কেটে আলোর সঞ্চার হবে এই আশায় কে তাঁর হাতে পদ্ম তুলে দেন?',
    a: ['ব্রহ্মা', 'শ্রীবিষ্ণু', 'শেষনাগ দেবী', 'বিশ্বকর্মা'],
    c: 0
  },
  {
    index: 3,
    q: 'দুর্গাপূজার সময় কুমারী পুজো প্রথা কিভাবে প্রচলিত হয়েছিল',
    a: ['বানাসুর বা কোলাসুরকে হত্যা করার মাধ্যমে', 'বেলুড় মঠে স্বামী বিবেকানন্দের দ্বারা', 'মহিষাসুর কে বধ করার জন্য', 'দুর্গম অসুর কে বধ করার জন্য'],
    c: 0
  },
  {
    index: 4,
    q: 'শাস্ত্রমতে শরৎকালের দুর্গাপুজোয় কোন রীতির মাধ্যমে মা দুর্গাকে আহ্বান জানানো হয়?',
    a: ['চক্ষুদান', 'ষষ্ঠীর বোধন', 'রথযাত্রার দিন কাঠামো তে মাটি দেওয়ার মাধ্যমে', 'কলাবৌ কে স্নান করিয়ে'],
    c: 1
  },
  {
    index: 5,
    q: 'দুর্গাপুজোর কোন দিনে ১০৮ টি প্রদীপ প্রজ্বলিত করা হয়',
    a: ['সপ্তমী', 'ষষ্ঠী', 'দশমী', 'অষ্টমী'],
    c: 3
  },
  {
    index: 6,
    q: "\"জটাজুট সমাযুক্তাং অর্ধেন্দু কৃতশেখরাম্\" এটি কোন মন্ত্র",
    a: ["দেবী দুর্গার পুষ্পাঞ্জলী মন্ত্র", "দেবী দুর্গার ধ্যান মন্ত্র", "দেবী দুর্গার বোধন মন্ত্র", "দেবী দুর্গার বিসর্জন মন্ত্র"],
    c: 1
  },
  {
    index: 7,
    q: "দশমীর দিন বিসর্জন পুজোর সময় কোন দেব বা দেবীর মুখ পান পাতা দিয়ে ঢাকা থাকে",
    a: ["সরস্বতী", "গণেশ", "লক্ষ্মী", "কার্তিক"],
    c: 2
  },
  {
    index: 8,
    q: "দশমীর দিন দেবী দুর্গার কোন রূপের পুজো হয়",
    a: ["উমা", "পার্বতী", "কালি", "অপরাজিতা"],
    c: 3
  },
  {
    index: 9,
    q: "দেবী দুর্গার বাহন সিংহের নাম কী",
    a: ["ঘটোক", "দাওন", "বৃষব", "ধনার্য্জন"],
    c: 1
  }
]

const Main = ({onOver, bgAudio, onWin}) => {

    const [layers, setLayers] = useState([0,0,0,0,0,0,0,0,0,0])
    // const [layers, setLayers] = useState([1,1,1,1,1,1,1,1,1,0])
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [questions, setQuestions] = useState([])

    const openLayer = (index) => {
        setLayers(prev => {
            const newPrev = [...prev]
            newPrev[index] = 1
            return newPrev
        })
    }

    useEffect(() => {
      setQuestions(shuffle(_questions))
    }, [])
    useEffect(() => {
      if(!layers.includes(0)){
        onWin()
      }
    }, [layers, onWin])

    const onSubmitHandler = (index) => {
      if(index === -1){
        onOver()
        return
      }
      setSelectedIndex(index)
      if(index === null){
        return
      }
      openLayer(index)
      setSelectedIndex(null)
    }

    const reset = () => {
      setQuestions(shuffle(_questions))
      setLayers([0,0,0,0,0,0,0,0,0,0])
      setSelectedIndex(null)
    }

  return (
    <div className='w-full h-screen flex flex-col items-center justify-center px-4 relative'>
        <Header bgAudio={bgAudio} onReset={reset} />
        <Background />
        <ImageLayers layers={layers} setIndex={setSelectedIndex} />
        {selectedIndex !== null ? <ViewQuestion data={questions[selectedIndex]} onSubmit={onSubmitHandler} /> : null}
        <Footer />       
    </div>
  )
}

export default Main