import React from 'react'

import durga from '../assets/durga-final.png'

const GameWin = ({onReset, onShare}) => {
  return (
    <div className='w-full h-screen flex flex-col items-center justify-start pt-24'>
        <p className='text-4xl font-bold mb-3'>Congratulation!</p>
        <img src={durga} className='h-64 mb-4' alt="" />
        <p className='text-2xl'>শুভ শারদীয়ার প্রীতি ও শুভেচ্ছা।</p>
        <p className='mb-6'>সবার পুজো ভাল কাটুক।</p>
        <div className='flex items-center gap-6 text-2xl'>
            <button onClick={onReset} className='py-2 px-4'><i className="fa-solid fa-arrow-rotate-right"></i></button>
            <button onClick={onShare} className='py-2 px-4'><i className="fa-solid fa-share-nodes"></i></button>
        </div>
    </div>
  )
}

export default GameWin