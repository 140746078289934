import React, { useState } from 'react'
import logo from '../assets/logo.png'

const Header = ({onReset, bgAudio}) => {

  const [muted, setMited] = useState(false)

  const audioStateChange = () => {
    bgAudio.muted = !bgAudio.muted
    setMited(bgAudio.muted)
  }

  return (
    <div className='flex items-center justify-between absolute z-[350] top-0 left-0 w-full py-2 pl-2 pr-4'>
        <div className='flex items-center divide-x'>
            <div className='px-2'>
                <img src={logo} className='h-12' alt="" />
            </div>
            <p className='font-heading px-2 text-5xl'>বুদ্ধিরূপেণঃ</p>
        </div>
       <div className='flex gap-3'>
          <button onClick={audioStateChange} className='text-2xl text-[#8C1C14]'>{muted ? <i className="fa-solid fa-volume-xmark"></i> : <i className="fa-solid fa-volume-low"></i>}</button>
          <button onClick={onReset} className='text-2xl text-[#8C1C14]'><i className="fa-solid fa-arrow-rotate-right"></i></button>
       </div>
    </div>
  )
}

export default Header