import React from 'react'
import logo from '../assets/logo.png'

const GameOver = ({onReset, onShare}) => {

  return (
    <div className='w-full flex-col h-screen flex justify-center items-center'>
        
        <div className='flex items-center divide-x mb-6'>
            <div className='px-2'>
                <img src={logo} className='h-8' alt="" />
            </div>
            <p className='font-heading px-2 text-xl'>বুদ্ধিরূপেণঃ</p>
        </div>
        <h2 className='text-5xl font-bold mb-2'>Game Over</h2>
        <p className='mb-4'>Try again</p>
        <div className='flex items-center gap-6 text-2xl'>
            <button onClick={onReset} className='py-2 px-4'><i className="fa-solid fa-arrow-rotate-right"></i></button>
            <button onClick={onShare} className='py-2 px-4'><i className="fa-solid fa-share-nodes"></i></button>
        </div>
    </div>
  )
}

export default GameOver