import React from 'react'
import bgImg from '../assets/puzzle/durga-bg.png'
import Points from './Points'

const ImageLayers = ({layers, setIndex}) => {
  return (
    <div className='relative w-full h-fit'>
        <img src={bgImg} alt="" className='w-full relative z-[400]' />
        {layers.map((item, index) => {
          if(item === 1){
            return <img key={index} src={require(`../assets/puzzle/durga-${index+1}.png`)} className='w-full h-full absolute top-0 left-0 z-[401] animate__animated animate__zoomIn' alt='' />
          }
          return null
        })}
        {layers[0] === 0 ? <Points className={`top-16 left-[calc(50%-12px)]`} onClick={() => setIndex(0)} /> : null}
        {layers[1] === 0 ? <Points className={`top-36 left-20`} onClick={() => setIndex(1)} /> : null}
        {layers[2] === 0 ? <Points className={`top-36 left-48`} onClick={() => setIndex(2)} /> : null}
        {layers[3] === 0 ? <Points className={`top-72 left-10`} onClick={() => setIndex(3)} /> : null}
        {layers[4] === 0 ? <Points className={`top-[360px] left-20`} onClick={() => setIndex(4)} /> : null}
        {layers[5] === 0 ? <Points className={`top-72 left-28`} onClick={() => setIndex(5)} /> : null}
        {layers[6] === 0 ? <Points className={`top-56 left-52`} onClick={() => setIndex(6)} /> : null}
        {layers[7] === 0 ? <Points className={`top-96 left-64`} onClick={() => setIndex(7)} /> : null}
        {layers[8] === 0 ? <Points className={`top-80 left-52`} onClick={() => setIndex(8)} /> : null}
        {layers[9] === 0 ? <Points className={`top-[30rem] left-[calc(50%-12px)]`} onClick={() => setIndex(9)} /> : null}
    </div>
  )
}

export default ImageLayers