import React, { useState } from 'react'
import Answer from './Answer'

const ViewQuestion = ({data, onSubmit}) => {

    const [selected, setSelected] = useState(false)

    const onSubmitHandler = (index) => {
        setSelected(true)
        setTimeout(() => {
            onSubmit(index === data.c ? data.index : -1)
        }, 700)
    }

    const onCloseHandler = () => {
        onSubmit(null)
    }

  return (
    <div className='fixed w-screen h-screen flex items-center justify-center bg-black/50 z-[500] px-8'>
        <div className='bg-white rounded-md w-full max-w-[384px] relative animate__animated animate__zoomIn'>
            <button onClick={onCloseHandler} className='absolute bg-zinc-200 h-8 w-8 text-zinc-800 -right-4 -top-4 rounded-full flex items-center justify-center'><i className="fa-solid fa-xmark"></i></button>
            <div className='py-2 px-4 text-2xl text-center bg-gradient-to-t from-zinc-50 border-b'>
                {data.q}
            </div>
            <div className='divide-y'>
                {data.a.map((item, index) => <Answer text={item} key={item} index={index} correct={data.c} onSubmit={onSubmitHandler} disabled={selected} />)}
            </div>
        </div>
    </div>
  )
}

export default ViewQuestion