import React from 'react'
import logo from '../assets/logo.png'

const Splash = ({onPlay}) => {

  return (
    <div className='w-full h-screen flex flex-col items-center justify-center'>
        <div className='flex items-center divide-x mb-6'>
            <div className='px-2'>
                <img src={logo} className='h-12' alt="" />
            </div>
            <p className='font-heading px-2 text-5xl'>বুদ্ধিরূপেণঃ</p>
        </div>
        <button className='text-3xl py-3 px-6' onClick={onPlay}><i className="fa-solid fa-play"></i></button>
    </div>
  )
}

export default Splash