import React, { useState } from 'react'

const Answer = ({text, onSubmit, index, correct, disabled= false}) => {

    const [feedback, setFeedBack] = useState(null)

    const onClickHandler = () => {
        if(correct === index){
            setFeedBack(1)
        }else{
            setFeedBack(0)
        }
        onSubmit(index)
    }

  return (
    <button disabled={disabled} className={`py-2 px-4 block w-full text-center text-lg ${feedback === 1 ? 'bg-green-200 text-green-600' : ''} ${feedback === 0 ? 'bg-red-200 text-red-600' : ''}`} onClick={onClickHandler}>{text}</button>
  )
}

export default Answer