import React from 'react'
import dhak from '../assets/dhak.png'

const Footer = () => {
  return (
    <div className='w-full py-2 px-4 left-0 bottom-4 text-center text-lg'>
        <p>Click on a 
          <img src={dhak} className='inline h-6 mx-1' alt="" /> to unlock the Goddess.</p>
    </div>
  )
}

export default Footer