import React from 'react'
import dhak from '../assets/dhak.png'

const Points = ({className, onClick}) => {
  return (
    <button onClick={onClick} className={`bg-transparent rounded-full flex items-center justify-center absolute ${className} z-[402]`}>
        <img src={dhak} className='w-8' alt="" />
    </button>
  )
}

export default Points