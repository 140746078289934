import { useEffect, useState } from "react";
import Main from "./Main";
import Splash from "./Components/Splash";
import GameOver from "./Components/GameOver";
import bg from './assets/bg.jpg'
import GameWin from "./Components/GameWin";

function App() {

  const [gameState, setGameState] = useState(0)
  const [bgAudio, setBgAudio] = useState(null)

  useEffect(() => {
    const audio = new Audio(require('./assets/backgroud.mp3'))
    audio.loop = true
    setBgAudio(audio)
  }, []);

  const onPlayHandler = () => {
    bgAudio.volume = 0.2;
    bgAudio.play()

    setGameState(1)
  }

  useEffect(() => {
    if(gameState === 1){
      if(bgAudio){
        bgAudio.loop = true
      }
    }else{
      if(bgAudio){
        bgAudio.loop = false
      }      
    }
  }, [gameState])


  const onShare = () => {
    if(navigator.share){
      navigator.share({
        title: "বুদ্ধিরূপেণঃ",
        text: "পারবো আমি! পারবে তুমি??",
        url: "/",
      })
    }
  }

  return (
    <div className="App">
      <div className="w-full max-w-[400px] mx-auto bg-center bg-cover bg-no-repeat" style={{backgroundImage: `url(${bg})`}}>
        {gameState === 0 ? <Splash onPlay={onPlayHandler} /> : null}
        {gameState === 1 ? <Main onOver={() => setGameState(2)} bgAudio={bgAudio} onWin={()=> setGameState(3)} /> : null}
        {gameState === 2 ? <GameOver onReset={() => setGameState(1)} onShare={onShare} /> : null}
        {gameState === 3 ? <GameWin onReset={() => setGameState(1)} onShare={onShare} /> : null}
      </div>
    </div>
  );
}

export default App;
