import React from 'react'
import mandala from '../assets/mandala.png'

const Background = () => {
  return (
    <div className='h-screen w-screen max-w-[400px] mx-auto absolute left-0 top-0 z-[300]'>
        <div className='w-full h-full flex justify-center items-start overflow-hidden pt-12 px-4'>
            <img src={mandala} className='w-full object-contain rotating-image' alt="" />
        </div>
    </div>
  )
}

export default Background